<template lang="pug">
b-row.route-list-item
  b-col.car-info(md="4")
    img.w-100(:src="`${apibase}/${package.cover}`")
  b-col.transfer-info(md="4")
    .package {{ package.name }}
    ul.detail-icons
      //- PAX
      li
        svg(xmlns='http://www.w3.org/2000/svg' width='15' height='15' fill='none' viewbox='0 0 15 15')
          path(fill='#6F7F8F' fill-rule='evenodd' d='M12.967 2.098c.488-.91.029-1.978-.78-1.978-.227 0-.455-.029-.69-.058-.729-.09-1.521-.189-2.568.538-1.198.831-1.36 3.104-.481 3.696.063.98.407 1.283.737 1.577.336.298.659.585.659 1.558 0 .977-.76 1.452-1.547 1.944l-.06.039c.634.477 1.138 1.072 1.138 2.02V15H15v-3.312c0-1.248-.914-1.837-1.777-2.394-.773-.499-1.504-.97-1.504-1.863 0-.89.311-1.18.64-1.487.371-.345.766-.713.766-1.984 0-.613 0-1.282-.158-1.862zM5.157 7.32l.001-.134L7.5 6.849s-.938-.49-.938-1.93v-1.44c0-.96-.937-1.92-2.343-1.92-1.407 0-2.344.96-2.344 1.92v1.44c0 1.44-.938 1.93-.938 1.93l2.342.336.002.134c0 .906-.731 1.385-1.504 1.89C.916 9.775 0 10.373 0 11.64V15h8.438v-3.36c0-1.267-.915-1.865-1.778-2.43-.772-.505-1.504-.984-1.504-1.89z' clip-rule='evenodd')
        | {{ rate().min }} - {{ rate().max }}
      //- BAGGAGE
      li
        svg(xmlns='http://www.w3.org/2000/svg' width='18' height='19' fill='none' viewbox='0 0 18 19')
          circle(cx='3' cy='18' r='1' fill='#6F7F8F')
          circle(cx='15' cy='18' r='1' fill='#6F7F8F')
          rect(width='6.6' height='4.6' x='5.7' y='.7' stroke='#6F7F8F' stroke-width='1.4' rx='1.3')
          path(fill='#6F7F8F' d='M0 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5z')
          path(fill='#fff' d='M3 14l3.5 2.02v-4.04L3 14zm10 0v.35a.35.35 0 0 0 .35-.35H13zm0-9l-2.02 3.5h4.04L13 5zm-6.85 9.35H13v-.7H6.15v.7zm7.2-.35V8.15h-.7V14h.7z')
          circle(cx='8.999' cy='10' r='5.833' fill='#fff')
          path(fill='#6F7F8F' d='M9 3a7 7 0 1 0 0 14A7 7 0 0 0 9 3zm1.457 10.849c-.36.142-.647.25-.862.324a2.274 2.274 0 0 1-.748.112c-.436 0-.776-.106-1.018-.32a1.03 1.03 0 0 1-.362-.81c0-.127.01-.257.027-.39.018-.132.047-.282.087-.45l.451-1.592c.04-.153.074-.298.101-.434.028-.136.04-.26.04-.375 0-.202-.041-.345-.125-.425-.085-.08-.244-.119-.482-.119-.116 0-.236.018-.358.054a5.095 5.095 0 0 0-.314.104l.12-.49c.294-.121.577-.224.847-.31s.524-.129.764-.129c.433 0 .768.106 1.003.314.234.21.352.482.352.816 0 .07-.008.191-.024.366a2.447 2.447 0 0 1-.09.48l-.45 1.588a4.49 4.49 0 0 0-.098.436 2.306 2.306 0 0 0-.043.371c0 .211.046.355.141.432.094.076.258.115.49.115.11 0 .233-.02.371-.058.138-.038.237-.071.3-.1l-.12.49zm-.08-6.447a1.07 1.07 0 0 1-.755.292 1.08 1.08 0 0 1-.759-.292.93.93 0 0 1-.315-.707.94.94 0 0 1 .315-.708c.211-.197.465-.295.759-.295s.547.098.755.295c.21.196.315.433.315.708a.93.93 0 0 1-.315.707z')
        //- | {{ rate().max - 1 }}
        | {{ baggages }}

    //- pre {{ package.overview_lines }}
    ul.policies
      li.d-flex.flex-row.align-items-center.mb-2(v-if="package.overview_lines.length > 0" v-for="(line, lIndex) in package.overview_lines" :key="lIndex")
        //- svg(width='13' height='13' fill='green' viewbox='0 0 13 13')
        //-   path(fill='' fill-rule='evenodd' d='M6.5 11.939a5.439 5.439 0 1 0 0-10.878 5.439 5.439 0 0 0 0 10.878zM6.5 13a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13zM6.036 2.255a.53.53 0 0 1 .53.53v3.649h2.72a.53.53 0 0 1 0 1.06h-3.25a.53.53 0 0 1-.53-.53V2.786a.53.53 0 0 1 .53-.53z' clip-rule='evenodd')
        //- Baggage
        img.mr-1(v-if="line.icon === 'default'" src="@/assets/icons/default.svg" width="30px" height="30px")
        img.mr-1(v-if="line.icon === 'baggage'" src="@/assets/icons/baggage.svg" width="30px" height="30px")
        img.mr-1(v-if="line.icon === 'baggage-plus'" src="@/assets/icons/baggage-plus.svg" width="30px" height="30px")
        img.mr-1(v-if="line.icon === 'drink'" src="@/assets/icons/drink.svg" width="30px" height="30px")
        img.mr-1(v-if="line.icon === 'water'" src="@/assets/icons/water.svg" width="30px" height="30px")
        img.mr-1(v-if="line.icon === 'lokum'" src="@/assets/icons/lokum.svg" width="30px" height="30px")
        img.mr-1(v-if="line.icon === 'standard-car'" src="@/assets/icons/standard-car.svg" width="30px" height="30px")
        img.mr-1(v-if="line.icon === 'comfort-car'" src="@/assets/icons/comfort-car.svg" width="30px" height="30px")
        | {{ line.description }}

  b-col.price-info(md="4" :class="package.color")
    .price {{ package.price }} {{ route.currency }}
    //- br
    //- .price TWO WAY: {{ package.two_way_price }} {{ route.currency }}
    //- .price {{ rate().price }} {{ route.currency }}
    //- .old-price {{ rate().price }} {{ route.currency }}
    button.btn.select(@click="preReservation()") SELECT
</template>

<script>
import axios from 'axios'

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL
const END_POINT = 'external-transfer/v1/transfer'

export default {
  name: 'RouteListComponent',
  components: { },
  props: {
    // eslint-disable-next-line vue/require-valid-default-prop
    route: { type: [Array, Object], default: [] },
    pax: { type: [Number, String], default: 2 },
    // eslint-disable-next-line vue/require-valid-default-prop
    package: { type: [Array, Object], default: [] }
  },
  data: () => ({}),
  computed: {
    apibase () {
      return API_BASE_URL
    },
    baggages () {
      // console.log(this.package.baggage_method)
      if (this.package.baggage_method === 'increase') {
        return this.rate().max + this.package.baggage_value
      } else if (this.package.baggage_method === 'decrease') {
        return this.rate().max - this.package.baggage_value
      }
      return this.rate().max
    }
  },
  mounted () {
  },
  methods: {
    preReservation () {
      const vm = this
      const formData = {
        service: 'transfer',
        pax: vm.pax,
        price: vm.package.price,
        currency: vm.route.currency,
        packages_stack: vm.package,
        rate_stack: vm.rate(),
        service_stack: vm.route
      }
      axios.post(`${END_POINT}/pre-reservation`, formData)
        .then((r) => {
          console.log(r.data)
          vm.$router.push({ name: 'transfer.booking', params: { uuid: r.data.data } })
        })
        .catch((e) => {
          console.log(e)
          console.log(e.response.data)
        })
    },
    rate () {
      const vm = this
      const recipe = vm.route.prices.find((x) => vm.pax >= x.min && vm.pax <= x.max)
      // console.log(recipe)
      return recipe
    }
  }
}
</script>

<style scoped lang="scss">
.route-list-item{
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 20px rgba(136, 136, 136, 0.30);
  min-height: 260px;
  margin-bottom: 40px;
  .car-info{
    padding: 24px;
    &:after{
      content: " ";
      width: 1px;
      height: 80%;
      position: absolute;
      right: 0;
      background-color: #dae4eb;
      top: 10%;
    }
  }
  .transfer-info{
    padding: 24px;
    .package{
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 15px;
    }
    ul.detail-icons{
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      li{
        &:not(:last-child){
          margin-right: 10px;
        }
        svg{
          margin-right: 5px;
        }
      }
    }
    ul.policies{
      list-style-type: none;
      margin: 10px 0 0 0;
      padding: 0;
      li{
        svg{
          margin-right: 10px;
        }
      }
    }
  }
  .price-info{
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to top,#ebeff2,#dae4eb 63%);
    border-radius: 0 8px 8px 0;
    .price{
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    &.orange{
      background: linear-gradient(to top, #ff8002, #f7c594 63%) !important;
    }
    &.yellow{
      background: linear-gradient(to top, #ff8001, #fdcb9a 63%) !important;
    }
    &.silver{
      background: linear-gradient(to top, #a7a9ac, #efefef 63%) !important;
    }
    &.gold{
      background: linear-gradient(to top, #a09167, #f7e5b3 63%) !important;
    }
  }
  button.select{
    color: #ffffff;
    border-radius: 11px;
    padding: 15px 30px;
    background: linear-gradient(to right, #6f6e6d, #8c8b8b 63%) !important;
    border: none;
    transition: all 500ms;
    box-shadow: 0px 3px 3px 2px rgba(108, 108, 108, 0.5);
    &:hover{
      transform: scale(1.1);
      box-shadow: 0px 5px 8px 2px rgba(108, 108, 108, 0.5);
    }
  }
}
</style>
